import { formatByType } from '@utils/formatting';

export type SurveyAnswersType = {
  curr_date: string | null;
  terms_of_use_and_disclaimer: string;
  p_birthdate: string | null;
  p_gender: string;
  p_years_in_canada: number | undefined;
  include_spouse: string;
  s_birthdate: string | null;
  s_gender: string;
  s_years_in_canada: number | undefined;
  p_monthly_cpp_pension: number | undefined;
  p_expected_future_cpp_pension_perc: number | undefined;
  p_cpp_start_age_toggle: string;
  p_cpp_start_age_override: number | undefined;
  p_monthly_oas_pension: number | undefined;
  p_oas_start_age_toggle: string;
  p_oas_start_age_override: number | undefined;
  p_current_rrsp_rrif: number | undefined;
  p_current_dc_pension_lif: number | undefined;
  p_current_tfsa: number | undefined;
  p_current_nts_assets: number | undefined;
  p_monthly_db_pension: number | undefined;
  p_db_pension_start_age: number | undefined;
  p_future_db_pension_increases: number | undefined;
  p_monthly_db_bridging: number | undefined;
  p_pre_retire_work_years: number | undefined;
  p_pre_retire_self_employed: string;
  p_pre_retire_annual_pay: number | undefined;
  p_pre_retire_full_time_incr: number | undefined;
  p_pre_retire_rrsp: number | undefined;
  p_pre_retire_use_max_tfsa: string;
  p_pre_retire_tfsa: number | undefined;
  p_pre_retire_you_dc: number | undefined;
  p_pre_retire_employer_dc: number | undefined;
  p_pre_retire_db: number | undefined;
  p_expect_future_earnings: string;
  p_future_part_time_pay: number | undefined;
  p_yrs_part_time_pay: number | undefined;
  s_monthly_cpp_pension: number | undefined;
  s_expected_future_cpp_pension_perc: number | undefined;
  s_cpp_start_age_toggle: string;
  s_cpp_start_age_override: number | undefined;
  s_monthly_oas_pension: number | undefined;
  s_oas_start_age_toggle: string;
  s_oas_start_age_override: number | undefined;
  s_current_rrsp_rrif: number | undefined;
  s_current_dc_pension_lif: number | undefined;
  s_current_tfsa: number | undefined;
  s_current_nts_assets: number | undefined;
  s_monthly_db_pension: number | undefined;
  s_db_pension_start_age: number | undefined;
  s_future_db_pension_increases: number | undefined;
  s_monthly_db_bridging: number | undefined;
  s_pre_retire_work_years: number | undefined;
  s_pre_retire_self_employed: string;
  s_pre_retire_annual_pay: number | undefined;
  s_pre_retire_full_time_incr: number | undefined;
  s_pre_retire_rrsp: number | undefined;
  s_pre_retire_use_max_tfsa: string;
  s_pre_retire_tfsa: number | undefined;
  s_pre_retire_you_dc: number | undefined;
  s_pre_retire_employer_dc: number | undefined;
  s_pre_retire_db: number | undefined;
  s_expect_future_earnings: string;
  s_future_part_time_pay: number | undefined;
  s_yrs_part_time_pay: number | undefined;
  p_inv_property_1: string;
  p_inv_property_1_mo_rent: number | undefined;
  p_inv_property_1_keep_yrs: number | undefined;
  p_inv_property_1_exp_sale_price: number | undefined;
  p_inv_property_2: string;
  p_inv_property_2_mo_rent: number | undefined;
  p_inv_property_2_keep_yrs: number | undefined;
  p_inv_property_2_exp_sale_price: number | undefined;
  p_future_windfall_net_tax_expenses: number | undefined;
  p_years_until_windfall: number | undefined;
  s_future_windfall_net_tax_expenses: number | undefined;
  s_years_until_windfall: number | undefined;
  p_old_annuity_amount: number | undefined;
  s_old_annuity_amount: number | undefined;
  p_purchased_annuity: number | undefined;
  s_purchased_annuity: number | undefined;
  annual_investment_fees: number;
  future_investment_fees: number;
  end_age: number;
  end_age_remaining_assets: number | undefined;
  p_current_age: number | undefined;
  s_current_age: number | undefined;
  p_cpp_start_age: number | undefined;
  p_oas_start_age: number | undefined;
  s_cpp_start_age: number | undefined;
  s_oas_start_age: number | undefined;
  hide_spouse_question?: boolean;
};

export const SurveyDefaultAnswersFcn: ({
  taxableAssetTotal,
  ntsTotal,
  override,
}: {
  taxableAssetTotal: { p: number; s: number };
  ntsTotal: { p: number; s: number };
  override?: SurveyAnswersType;
}) => SurveyAnswersType = ({ taxableAssetTotal, ntsTotal, override }) => ({
  curr_date: formatByType({ type: 'date_sql', val: new Date() }),
  terms_of_use_and_disclaimer: '',
  p_birthdate: null,
  p_gender: '',
  p_years_in_canada: 0,
  include_spouse: '',
  s_birthdate: null,
  s_gender: '',
  s_years_in_canada: 0,
  p_monthly_cpp_pension: 0,
  p_expected_future_cpp_pension_perc: 0,
  p_cpp_start_age_toggle: 'y',
  p_cpp_start_age_override: 0,
  p_monthly_oas_pension: 0,
  p_oas_start_age_toggle: 'y',
  p_oas_start_age_override: 0,
  p_current_rrsp_rrif: 0,
  p_current_dc_pension_lif: 0,
  p_current_tfsa: 0,
  p_current_nts_assets: 0,
  p_monthly_db_pension: 0,
  p_db_pension_start_age: 0,
  p_future_db_pension_increases: 0,
  p_monthly_db_bridging: 0,
  p_pre_retire_work_years: 0,
  p_pre_retire_self_employed: '',
  p_pre_retire_annual_pay: 0,
  p_pre_retire_full_time_incr: 0,
  p_pre_retire_rrsp: 0,
  p_pre_retire_use_max_tfsa: 'n',
  p_pre_retire_tfsa: 0,
  p_pre_retire_you_dc: 0,
  p_pre_retire_employer_dc: 0,
  p_pre_retire_db: 0,
  p_expect_future_earnings: '',
  p_future_part_time_pay: 0,
  p_yrs_part_time_pay: 0,
  s_monthly_cpp_pension: 0,
  s_expected_future_cpp_pension_perc: 0,
  s_cpp_start_age_toggle: 'y',
  s_cpp_start_age_override: 0,
  s_monthly_oas_pension: 0,
  s_oas_start_age_toggle: 'y',
  s_oas_start_age_override: 0,
  s_current_rrsp_rrif: 0,
  s_current_dc_pension_lif: 0,
  s_current_tfsa: 0,
  s_current_nts_assets: 0,
  s_monthly_db_pension: 0,
  s_db_pension_start_age: 0,
  s_future_db_pension_increases: 0,
  s_monthly_db_bridging: 0,
  s_pre_retire_work_years: 0,
  s_pre_retire_self_employed: '',
  s_pre_retire_annual_pay: 0,
  s_pre_retire_full_time_incr: 0,
  s_pre_retire_rrsp: 0,
  s_pre_retire_use_max_tfsa: 'n',
  s_pre_retire_tfsa: 0,
  s_pre_retire_you_dc: 0,
  s_pre_retire_employer_dc: 0,
  s_pre_retire_db: 0,
  s_expect_future_earnings: '',
  s_future_part_time_pay: 0,
  s_yrs_part_time_pay: 0,
  p_inv_property_1: 'n',
  p_inv_property_1_mo_rent: 0,
  p_inv_property_1_keep_yrs: 0,
  p_inv_property_1_exp_sale_price: 0,
  p_inv_property_2: 'n',
  p_inv_property_2_mo_rent: 0,
  p_inv_property_2_keep_yrs: 0,
  p_inv_property_2_exp_sale_price: 0,
  p_future_windfall_net_tax_expenses: 0,
  p_years_until_windfall: 0,
  s_future_windfall_net_tax_expenses: 0,
  s_years_until_windfall: 0,
  p_old_annuity_amount: 0,
  s_old_annuity_amount: 0,
  p_purchased_annuity: taxableAssetTotal.p * 0.2,
  s_purchased_annuity: taxableAssetTotal.s * 0.2,
  annual_investment_fees: 0.6,
  future_investment_fees: 3.5,
  end_age: 93,
  end_age_remaining_assets: Math.max(
    (taxableAssetTotal.p + taxableAssetTotal.s + ntsTotal.p + ntsTotal.s) * 0.1,
    5000,
  ),
  p_current_age: undefined,
  s_current_age: undefined,
  p_cpp_start_age: undefined,
  p_oas_start_age: undefined,
  s_cpp_start_age: undefined,
  s_oas_start_age: undefined,
  ...(override || {}),
});
