/* eslint-disable no-throw-literal */

export const apiErrorProcessing = (err: {
  response: { status: number; data: { error: { code: string } } };
}) => {
  if (err.response) {
    if (+err.response.status === 401) {
      throw { code: 'INVALID_TOKEN' };
    }

    if (+err.response.status === 400 && err.response.data) {
      if (err.response.data.error) {
        if (err.response.data.error.code) {
          throw { code: err.response.data.error.code };
        }
      }
    }
  }
  throw { code: 'MAJOR' };
};

export interface ErrorMessageType {
  title: string;
  message: string;
}

interface ErrorMessageScreenType {
  [key: string]: {
    [key: string]: ErrorMessageType;
  };
}

const MAJOR_ERROR: ErrorMessageType = {
  title: 'Service Unavailable',
  message:
    'We encountered an issue while processing your request. If the problem persists, send us an email at info@perc-pro.ca.',
};

const messages: ErrorMessageScreenType = {
  GLOBAL: {},
  REGISTER: {
    MISMATCH: {
      title: 'Registration Error',
      message:
        'Your passwords do not match. Please re-enter your passwords and try again',
    },
    InvalidParameterException: {
      title: 'Registration Error',
      message: 'Please complete all of the fields',
    },
    UsernameExistsException: {
      title: 'Registration Error',
      message:
        'The email address you entered is already in use. If this is your email try logging in or resetting your password.',
    },
    InvalidPasswordException: {
      title: 'Registration Error',
      message:
        'Please create a stronger password. It must be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    },
  },
  LOGIN: {
    NotAuthorizedException: {
      title: 'Login Error',
      message: 'Please enter a valid email and password',
    },
    InvalidParameterException: {
      title: 'Login Error',
      message: 'Please enter a valid email and password',
    },
    USER_DISABLED: {
      title: 'Login Error',
      message:
        'Unfortunately, your account has been disabled. If you think this was done incorrectly, please contact us.',
    },
  },
  VERIFY_USER: {
    NotAuthorizedException: {
      title: 'Verification Error',
      message: 'We were not able to resend the email, please try again.',
    },
    ExpiredCodeException: {
      title: 'Expired Link',
      message:
        'Looks like you are using an old link. Please login again and re-request the link.',
    },
  },
  SIGNOUT: {},
  FORGOT_PASSWORD: {
    INVALID_PARAMETER: {
      title: 'Reset Error',
      message: 'Please enter a valid email',
    },
  },
  RESET_PASSWORD: {
    ExpiredCodeException: {
      title: 'Expired Code',
      message:
        'Looks like you are using an old code. Please re-request your code and try again.',
    },
    CodeMismatchException: {
      title: 'Incorrect Verification Code',
      message:
        'You provided an incorrect code. Please re-enter your code and try again.',
    },
    InvalidParameterException: {
      title: 'Reset Password Error',
      message:
        'Please enter a valid password. Ensure your password is at least 8 characters long and contains 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    },
    InvalidPasswordException: {
      title: 'Weak Password',
      message:
        'Please create a stronger password. It must be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    },
  },
  MAIN_PAGE: {},
  HOME_PAGE: {},
  SURVEY_PAGE: {
    INVALID_VALUES: {
      title: 'Missing Values',
      message: 'Please ensure that you answer all questions before submitting.',
    },
    MISSING_PROFILE: {
      title: 'Missing Profile',
      message:
        "The profile you are trying to access either belongs to someone else or doesn't exist. Please try again",
    },
    SUBSCRIBE_TO_VIEW: {
      title: 'Subscription Required',
      message:
        'In order to view this profile you must have at least one active subscription.',
    },
    INVALID_PROFILE_ORDER: {
      title: 'Invalid Profile',
      message:
        'The basic PERC profile you are trying to use does not belong to you. Please create a new profile and try ordering again',
    },
    INVALID_CARD: {
      title: 'Incorrect Card Details',
      message:
        'Looks like the card details you entered are incorrect. Please double check and resubmit.',
    },
    INVALID_PROMO_CODE: {
      title: 'Invalid Code',
      message:
        'The code you entered is invalid, please double check the code and try again.',
    },
    INVALID_SUBSCRIPTION: {
      title: 'Invalid Subscription',
      message:
        'To order a custom report you must have a subscription. Choose a basic profile and order a subscription.',
    },
    EXPIRED_SUBSCRIPTION: {
      title: 'Expired Subscription',
      message:
        'Your subscription has expired. To renew it, go to the basic profile that you want the subscription for and order it again.',
    },
    DELETE_NO_SUBSCRIPTION: {
      title: 'Unable to Delete',
      message:
        'In order to delete a profile, you must have at least one active subscription. Subscribe now to unlock this feature.',
    },
    DELETE_UNAUTHORIZED: {
      title: 'Unable to Delete',
      message:
        'You are not authorized to delete this profile. Please contact us if you require further assistance.',
    },
    CANT_CONVERGE: {
      title: 'No optimal solution',
      message:
        'The data you provided does not have an optimal solution, please double check your numbers. If the problem persists please reach out to us.',
    },
    UNABLE_TO_LOAD_PRICING: {
      title: 'Invalid Pricing',
      message:
        'Unfortunately we encountered an issue loading this page, please refresh the page and try again.',
    },
  },
  SUBSCRIPTION_PAGE: {
    INVALID_CARD: {
      title: 'Incorrect Card Details',
      message:
        'Looks like the card details you entered are incorrect. Please double check and resubmit.',
    },
    INVALID_PROMO_CODE: {
      title: 'Invalid Code',
      message:
        'The code you entered is invalid, please double check the code and try again.',
    },
    INVALID_SUBSCRIPTION: {
      title: 'Invalid Subscription',
      message:
        'To order a custom report you must have a subscription. Choose a basic profile and order a subscription.',
    },
    EXPIRED_SUBSCRIPTION: {
      title: 'Expired Subscription',
      message:
        'Your subscription has expired. To renew it, go to the basic profile that you want the subscription for and order it again.',
    },
    EXISTING_SUBSCRIPTION: {
      title: 'Existing Subscription',
      message:
        'You already have an active subscription. Click the "My Profiles" button to start creating custom profiles.',
    },
  },
  CUSTOMIZE_PAGE: {
    INVALID_VALUES: {
      title: 'Missing Values',
      message: 'Please ensure that you answer all questions before submitting.',
    },
    MISSING_PROFILE: {
      title: 'Missing Profile',
      message:
        "The profile you are trying to access either belongs to someone else or doesn't exist. Please try again",
    },
  },
  DOWNLOAD_PAGE: {
    UNKNOWN_PROFILE: {
      title: 'Unknown Profile',
      message: 'This profile does not exist please double check and try again',
    },
  },
  USER_PAGE: {
    INVALID_FIRST_NAME: {
      title: 'Invalid First Name',
      message: 'Please enter a valid first name',
    },
    INVALID_LAST_NAME: {
      title: 'Invalid Last Name',
      message: 'Please enter a valid last name',
    },
    INVALID_PROFILE_NUMBER: {
      title: 'Invalid Profile Number',
      message: 'Please enter a valid profile number',
    },
    SUBSCRIBE_TO_VIEW: {
      title: 'Subscription Required',
      message:
        'In order to view this profile you must have at least one active subscription.',
    },
  },
  TIPS_PAGE: {
    NOT_PERMITTED: {
      title: 'Not Allowed',
      message: 'You do not have permission to edit this page.',
    },
  },
};

export type ScreenKeyType =
  | 'GLOBAL'
  | 'LOGIN'
  | 'VERIFY_USER'
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD'
  | 'REGISTER'
  | 'SIGNOUT'
  | 'MAIN_PAGE'
  | 'HOME_PAGE'
  | 'SURVEY_PAGE'
  | 'CUSTOMIZE_PAGE'
  | 'SUBSCRIPTION_PAGE'
  | 'DOWNLOAD_PAGE'
  | 'USER_PAGE'
  | 'TIPS_PAGE';

export const ERRORS = (screen: ScreenKeyType, val: string) =>
  messages[screen][val] || MAJOR_ERROR;
