export const SURVEY_ERRORS: { [key: string]: string } = {
  terms_of_use_and_disclaimer: 'Please agree to the terms to continue.',
  p_birthdate: 'Please choose a valid date',
  p_gender: 'Please pick one option',
  p_years_in_canada: 'Please enter a valid number',
  include_spouse: 'Please pick one option',
  s_birthdate: 'Please choose a valid date',
  s_gender: 'Please pick one option',
  s_years_in_canada: 'Please enter a valid number',
  p_monthly_cpp_pension: 'Please enter a valid number',
  p_expected_future_cpp_pension_perc: 'Please enter a valid number',
  p_cpp_start_age_toggle: 'Please pick one option',
  p_cpp_start_age_override: 'Please enter a valid number',
  p_monthly_oas_pension: 'Please enter a valid number',
  p_oas_start_age_toggle: 'Please pick one option',
  p_oas_start_age_override: 'Please enter a valid number',
  p_current_rrsp_rrif: 'Please enter a valid number',
  p_current_dc_pension_lif: 'Please enter a valid number',
  p_current_tfsa: 'Please enter a valid number',
  p_current_nts_assets: 'Please enter a valid number',
  p_monthly_db_pension: 'Please enter a valid number',
  p_db_pension_start_age: 'Please enter a valid number',
  p_future_db_pension_increases: 'Please enter a valid number',
  p_monthly_db_bridging: 'Please enter a valid number',
  p_pre_retire_work_years: 'Please enter a valid number',
  p_pre_retire_self_employed: 'Please pick one option',
  p_pre_retire_annual_pay: 'Please enter a valid number',
  p_pre_retire_full_time_incr: 'Please enter a valid number',
  p_pre_retire_rrsp: 'Please enter a valid number',
  p_pre_retire_use_max_tfsa: 'Please pick one option',
  p_pre_retire_tfsa: 'Please enter a valid number',
  p_pre_retire_you_dc: 'Please enter a valid number',
  p_pre_retire_employer_dc: 'Please enter a valid number',
  p_pre_retire_db: 'Please enter a valid number',
  p_expect_future_earnings: 'Please pick one option',
  p_future_part_time_pay: 'Please enter a valid number',
  p_yrs_part_time_pay: 'Please enter a valid number',
  s_monthly_cpp_pension: 'Please enter a valid number',
  s_expected_future_cpp_pension_perc: 'Please enter a valid number',
  s_cpp_start_age_toggle: 'Please pick one option',
  s_cpp_start_age_override: 'Please enter a valid number',
  s_monthly_oas_pension: 'Please enter a valid number',
  s_oas_start_age_toggle: 'Please pick one option',
  s_oas_start_age_override: 'Please enter a valid number',
  s_current_rrsp_rrif: 'Please enter a valid number',
  s_current_dc_pension_lif: 'Please enter a valid number',
  s_current_tfsa: 'Please enter a valid number',
  s_current_nts_assets: 'Please enter a valid number',
  s_monthly_db_pension: 'Please enter a valid number',
  s_db_pension_start_age: 'Please enter a valid number',
  s_future_db_pension_increases: 'Please enter a valid number',
  s_monthly_db_bridging: 'Please enter a valid number',
  s_pre_retire_work_years: 'Please enter a valid number',
  s_pre_retire_self_employed: 'Please pick one option',
  s_pre_retire_annual_pay: 'Please enter a valid number',
  s_pre_retire_full_time_incr: 'Please enter a valid number',
  s_pre_retire_rrsp: 'Please enter a valid number',
  s_pre_retire_use_max_tfsa: 'Please pick one option',
  s_pre_retire_tfsa: 'Please enter a valid number',
  s_pre_retire_you_dc: 'Please enter a valid number',
  s_pre_retire_employer_dc: 'Please enter a valid number',
  s_pre_retire_db: 'Please enter a valid number',
  s_expect_future_earnings: 'Please pick one option',
  s_future_part_time_pay: 'Please enter a valid number',
  s_yrs_part_time_pay: 'Please enter a valid number',
  p_inv_property_1: 'Please pick one option',
  p_inv_property_1_mo_rent: 'Please enter a valid number',
  p_inv_property_1_keep_yrs: 'Please enter a valid number',
  p_inv_property_1_exp_sale_price: 'Please enter a valid number',
  p_inv_property_2: 'Please pick one option',
  p_inv_property_2_mo_rent: 'Please enter a valid number',
  p_inv_property_2_keep_yrs: 'Please enter a valid number',
  p_inv_property_2_exp_sale_price: 'Please enter a valid number',
  p_future_windfall_net_tax_expenses: 'Please enter a valid number',
  p_years_until_windfall: 'Please enter a valid number',
  s_future_windfall_net_tax_expenses: 'Please enter a valid number',
  s_years_until_windfall: 'Please enter a valid number',
  p_old_annuity_amount: 'Please enter a valid number',
  s_old_annuity_amount: 'Please enter a valid number',
  p_purchased_annuity: 'Please enter a valid number',
  s_purchased_annuity: 'Please enter a valid number',
  annual_investment_fees: 'Please make a valid selection',
  future_investment_fees: 'Please make a valid selection',
  end_age: 'Please make a valid selection',
  end_age_remaining_assets: 'Please enter a valid number',
  p_current_age: 'Please enter a valid number',
  s_current_age: 'Please enter a valid number',
  p_cpp_start_age: 'Please enter a valid number',
  p_oas_start_age: 'Please enter a valid number',
  s_cpp_start_age: 'Please enter a valid number',
  s_oas_start_age: 'Please enter a valid number',
};
